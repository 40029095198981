import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SupplierGrid } from './SupplierGrid/SupplierGrid';
import { SuppliersFilter } from './SuppliersFilter';
import { PageLayout } from '../common/PageLayout';
import { PageHeader } from '../common/PageHeader';
import { SearchToolbar } from '../common/SearchToolbar';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import * as Store from '../../store';
import { TSupplier } from '../../apiClient/suppliers/suppliers';
import { TProcessingSupplier } from '../../store/suppliers/suppliers';
import { useUser } from '../../contexts/UserContext';

export const SuppliersList: React.FC = () => {
  const dispatch = Store.useDispatch();
  const { t } = useTranslation();
  const processingData = useSelector(Store.suppliers.selectors.selectProcessingIndexes);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const supplierIndexes = useSelector(Store.suppliers.selectors.selectSupplierIndexes);
  const { isAdmin } = useUser();

  
  const [filters, setFilters] = useState<{ type?: string; country?: string }>({});
  const [filteredSuppliers, setFilteredSuppliers] = useState<TSupplier[]>([]);

  useEffect(() => {
    dispatch(Store.suppliers.processingSuppliers());
    dispatch(Store.suppliers.fetchAffilizSuppliers());
  }, [dispatch]);

  const filterSuppliers = (
    supplierIndexes: Record<string, TSupplier>,
    filters: { type?: string; country?: string }
  ) => {
    let filtered = Object.values(supplierIndexes || {});

    if (filters.type) {
      filtered = filtered.filter((supplier) => {
        if (filters.type === 'Site Marchand') {
          return supplier.type === 'supplier';
        }
        if (filters.type === 'Site professionnel') {
          return supplier.type === 'pro';
        }
        return true;
      });
    }
    if (filters.country) {
      filtered = filtered.filter((supplier) => supplier.country === filters.country);
    }

    return filtered;
  };

  useEffect(() => {
    const filteredSuppliers = filterSuppliers(supplierIndexes, filters); 
    console.log("ici supllier index ==>"  , supplierIndexes)
    setFilteredSuppliers(filteredSuppliers);
  }, [filters, supplierIndexes]);

  const handleStatusClick = (supplier: TSupplier) => {
    console.log('Status clicked:', supplier.name);
  };

  const sendToProcess = async (id: string) => {
    try {
      console.log('Processing supplier:', id);
      dispatch(Store.suppliers.sendToProcess(id));
      toast.success(t('suppliers.processingStarted'));
    } catch (error: unknown) {
      console.error(error);
      toast.error(t('common.error'));
    }
  };

  const handleToggleActive = async (id: string, isActive: boolean) => {
    try {
      dispatch(Store.suppliers.toggleActive({ supplierId: id, isActive }));
      toast.success(t('suppliers.statusUpdated'));
    } catch (error: unknown) {
      console.error(error);
      toast.error(t('common.error'));
    }
  };

  const handleSupplierUpdate = async (id: string, updates: Partial<TSupplier>) => {
    try {
      console.log('Updating supplier:', id, updates);
      dispatch(Store.suppliers.updateSupplier({ supplierId: id, supplier: updates }));
      toast.success(t('suppliers.updated'));
    } catch (error: unknown) {
      console.error(error);
      toast.error(t('common.error') + ' ' + error);
    }
  };

  // Je transform mon tableau en  Record<string, TSupplier> en utilisant la méthode Object.fromEntries
  const suppliersRecord = Object.fromEntries(
    filteredSuppliers.map((supplier) => [supplier._id, supplier])
  );

  //console.log("my supplier index is ==>" , supplierIndexes)
  // console.log("my suppliers filtrer sont ==>" , suppliersRecord)
  return (
    <PageLayout
      header={
        <PageHeader title={t('suppliers.title')} description={t('suppliers.description')}>
          <SearchToolbar
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            onFilterClick={() => setIsFilterOpen(true)}
            onSearch={() => {}}
            placeholder={t('suppliers.searchPlaceholder')}
          />
        </PageHeader>
      }
    >
      {isAdmin && (
        <button
          type="button"
          className="mb-4 rounded-md border border-gray-300 bg-orange-500 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-orange-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={() => {
            try {
              dispatch(Store.suppliers.refreshOctoParse());
              toast.success(t('suppliers.refreshed'));
            } catch (error) {
              console.error(error);
              toast.error(t('common.error'));
            }
          }}
        >
          {t('suppliers.refreshOctoParse')}
        </button>
      )}
      <div className="mb-4 text-lg font-medium">
        {filteredSuppliers.length} {t('suppliers.found')}
      </div>
      <SupplierGrid
        suppliers={suppliersRecord}
        processingData={processingData as unknown as Record<string, TProcessingSupplier>}
        onStatusClick={handleStatusClick}
        onProcess={sendToProcess}
        onToggleActive={handleToggleActive}
        onSupplierUpdate={handleSupplierUpdate}
      />

      <SuppliersFilter
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        onApplyFilters={setFilters}
      />
    </PageLayout>
  );
};
