import { useMemo } from 'react';
import * as Store from '../store';
import { TSupplier } from '../apiClient/suppliers/suppliers';

type TFilterInputKey =
  | 'amazonPrice'
  | 'roi'
  | 'estimatedSales'
  | 'profit'
  | 'profitPerMonth'
  | 'supplierPrice';

export type TInputRange = {
  label: string;
  name: TFilterInputKey;
  min: number;
  max: number;
  step: number;
};

export type TFilterRanges = {
  amazonPrice?: { min: number; max: number };
  supplierPrice?: { min: number; max: number };
  roi?: { min: number; max: number };
  estimatedSales?: { min: number; max: number };
  profit?: { min: number; max: number };
  profitPerMonth?: { min: number; max: number };
  isGrouped?: boolean;
  IsAmazonFulfilled?: boolean;
  amazonCountry?: string[];
  asinList?: string[];
  excludeList?: string[];
  supplier?: string[];
};

export const InputRangesData: TInputRange[] = [
  { label: 'filters.profit.amazonPrice', name: 'amazonPrice', min: 0, max: 1000, step: 10 },
  { label: 'filters.profit.roiUnit', name: 'roi', min: 0, max: 100, step: 10 },
  { label: 'filters.profit.salesPerMonth', name: 'estimatedSales', min: 0, max: 100, step: 10 },
  { label: 'filters.profit.profitUnit', name: 'profit', min: 0, max: 1000, step: 10 },
  { label: 'filters.profit.profitPerMonth', name: 'profitPerMonth', min: 0, max: 10000, step: 10 },
];

export const useSupplierOptions = (supplierType: string | null) => {
  const suppliers = Store.useAppSelector(Store.suppliers.selectors.selectSupplierCollection);
  return useMemo(
    () =>
      supplierType
        ? suppliers
            .filter((supplier) => supplier.type === supplierType)
            .map((supplier) => ({
              value: supplier._id,
              label: supplier.name as string,
            }))
        : [],
    [suppliers, supplierType]
  );
};

export type TFilterState = {
  [key in
    | 'amazonPrice'
    | 'supplierPrice'
    | 'roi'
    | 'estimatedSales'
    | 'profit'
    | 'profitPerMonth']: number | string[] | number[] | string;
};

const rangeInitialState = {
  amazonPrice: { min: 0, max: 10000 },
  supplierPrice: { min: 0, max: 10000 },
  roi: { min: 0, max: 10000 },
  estimatedSales: { min: 0, max: 10000 },
  profit: { min: 0, max: 10000 },
  profitPerMonth: { min: 0, max: 10000 },
};
const InitialState: TFilterRanges = {
  ...rangeInitialState,
  isGrouped: false,
  IsAmazonFulfilled: false,
};

type RangeValue = { min: number; max: number };

const isRangeValue = (value: unknown): value is RangeValue => {
  return typeof value === 'object' && value !== null && 'min' in value && 'max' in value;
};

export const cleanFilterState = (state: TFilterRanges) => {
  return Object.keys(state).reduce((acc, key) => {
    const typedKey = key as keyof TFilterRanges;
    const initialValue = InitialState[typedKey];
    const value = state[typedKey];

    // Handle boolean values
    if (typedKey === 'isGrouped' || typedKey === 'IsAmazonFulfilled') {
      if (value !== initialValue) {
        return { ...acc, [key]: value };
      }
      return acc;
    }

    // Handle range values
    if (isRangeValue(value) && isRangeValue(initialValue)) {
      if (value.min === initialValue.min && value.max === initialValue.max) {
        return acc;
      }
      return { ...acc, [key]: value };
    }

    return acc;
  }, {} as TFilterRanges);
};

export const formatSearchConditionsToFilterState = (conditions: Record<string, unknown>) => {
  return Object.keys(conditions).reduce((acc, key) => {
    if (
      conditions[key] &&
      (conditions[key] as Record<string, unknown>).$gte &&
      (conditions[key] as Record<string, unknown>).$lte
    ) {
      return {
        ...acc,
        [key]: {
          min: (conditions[key] as Record<string, unknown>).$gte,
          max: (conditions[key] as Record<string, unknown>).$lte,
        },
      };
    }
    if (conditions[key] && (conditions[key] as Record<string, unknown>).$in) {
      return { ...acc, [key]: (conditions[key] as Record<string, unknown>).$in };
    }
    if (conditions[key] && (conditions[key] as Record<string, unknown>).$nin) {
      return { ...acc, [key]: (conditions[key] as Record<string, unknown>).$nin };
    }
    if (conditions[key] && (conditions[key] as Record<string, unknown>).$eq) {
      return { ...acc, [key]: (conditions[key] as Record<string, unknown>).$eq };
    }
    if (conditions[key] && (conditions[key] as Record<string, unknown>).$ne) {
      return { ...acc, [key]: (conditions[key] as Record<string, unknown>).$ne };
    }
    if (conditions[key] && (conditions[key] as Record<string, unknown>).$gt) {
      return { ...acc, [key]: (conditions[key] as Record<string, unknown>).$gt };
    }
    if (conditions[key] && (conditions[key] as Record<string, unknown>).$lt) {
      return { ...acc, [key]: (conditions[key] as Record<string, unknown>).$lt };
    }
    return acc;
  }, {} as TFilterRanges);
};

export const formatFilterStateToSearchConditions = ({
  state,
  suppliers,
  asinList,
  eanList,
  eanExcludeList,
  excludeList,
  amazonCountry,
}: {
  state?: TFilterRanges;
  suppliers?: string[];
  asinList?: string[];
  eanList?: string[];
  eanExcludeList?: string[];
  excludeList?: string[];
  amazonCountry?: string[];
}) => {
  const searchConditions: Record<string, unknown> = {};
  if (state) {
    for (const key of Object.keys(state)) {
      const typedKey = key as keyof TFilterRanges;
      const value = state[typedKey];

      if (typedKey === 'isGrouped' || typedKey === 'IsAmazonFulfilled') {
        if (typedKey === 'IsAmazonFulfilled' && value === true) {
          searchConditions[typedKey] = { $ne: true };
        }
        if (typedKey === 'isGrouped' && value === true) {
          searchConditions[typedKey] = { $ne: true };
        } else {
          searchConditions[typedKey] = value;
        }
      } else if (isRangeValue(value)) {
        searchConditions[typedKey] = {
          $gte: value.min,
          $lte: value.max,
        };
      } else if (Array.isArray(value)) {
        const key = typedKey === 'asinList' ? 'asin' : typedKey;
        searchConditions[key] = {
          $in: value,
        };
      } else {
        searchConditions[typedKey] = value;
      }
    }
  }

  if (suppliers && suppliers.length > 0) {
    searchConditions['supplier'] = {
      $in: suppliers,
    };
  }
  if (asinList && asinList.length > 0) {
    searchConditions['asin'] = {
      $in: asinList,
    };
  }
  if (eanList && eanList.length > 0) {
    searchConditions['ean'] = {
      $in: eanList,
    };
  }
  if (excludeList && excludeList.length > 0) {
    searchConditions['asin'] = {
      $nin: excludeList,
    };
  }
  if (eanExcludeList && eanExcludeList.length > 0) {
    searchConditions['ean'] = {
      $nin: eanExcludeList,
    };
  }
  if (amazonCountry && amazonCountry.length > 0) {
    searchConditions['amazonCountry'] = {
      $in: amazonCountry,
    };
  }
  return searchConditions;
};

export const getAffilizzUrl = (
  id: string,
  url: string,
  supplierIndexes: Record<string, TSupplier>
) => {
  const supplier = supplierIndexes[id] as unknown as TSupplier;

  if (supplier?.hasAffiliation || supplier?.affilizId || url.includes('awin')) {
    url = `https://redirect.affilizz.com/r?u=${encodeURIComponent(url)}&m=66660bf51d631305a36a9f85`;
  }
  return url;
};
