import {
  Route,
  Navigate,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { UserProvider, useUser } from './contexts/UserContext';
import { Sidebar } from './components/Sidebar/Sidebar';
import { ProductSearch } from './components/Search/ProductSearch';
import { ReverseSearch } from './components/ReverseSearch/ReverseSearch';
import { ScanList } from './components/ScanList/ScanList';
import { SuppliersList } from './components/Suppliers/SuppliersList';
import { AffilizSuppliersPage } from './pages/AffilizSuppliersPage';
import { ProductDetails } from './pages/ProductDetails';
import { DashboardPage } from './pages/DashboardPage';
import { SupportForm } from './pages/SupportContact';
import { Auth } from './pages/Auth';
import { ProfileForm } from './components/User/ProfileForm';
import { UserListPage } from './pages/UserListPage';
import { LanguageSwitcher } from './components/LanguageSwitcher';


import Login from './pages/Login';
import { UserMenu } from './components/User/UserMenu';
import { useSelector } from 'react-redux';
import * as Store from './store';
import { useEffect ,useState } from 'react';
import { setToken, getToken, clearToken } from './services/token.service';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SavedAsinsPage } from './pages/SavedAsinsPage';
import AccountPage from './components/User/AccountPage';
import { ForgotPassword } from './pages/ForgotPassword';
import { ResetPassword } from './pages/ResetPassword';
import { VerifyEmail } from './pages/VerifyEmail';
import { SavedSearchesPage } from './pages/SavedSearchesPage';
import { ClearDataPage } from './pages/ClearDataPage'; 
import {ScanPreviewPage} from   './components/ScanList/ScanPreviewPage'

import { AlignJustify } from 'lucide-react';

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'; 


toastr.options = {
  timeOut: 3000,
  positionClass: 'toast-top-right',
  closeButton: true,
  progressBar: true,
  newestOnTop: true,
  rtl: false,
  preventDuplicates: false,
}

// Pass props to AccountPage through useOutletContext instead
const ProtectedLayout = () => {
  const dispatch = Store.useDispatch();
  const isAuthenticated = useSelector(Store.auth.selectIsAuthenticated);
  const { setUser } = useUser();
  const currentUser = useSelector(Store.auth.selectUser);
  const token = useSelector(Store.auth.selectToken);
  const authToken: string | null = getToken();  

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };


  useEffect(() => {
    if (authToken === 'null' && token) {
      setToken(token);
    }
  }, [authToken, token]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(Store.auth.fetchCurrentUser());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && (currentUser?.hasBasicPlan || currentUser?.hasPremiumPlan)) {
      dispatch(Store.suppliers.fetchSuppliers());
    }
  }, [
    isAuthenticated,
    dispatch,
    currentUser?.hasBasicPlan,
    currentUser?.hasPremiumPlan,
    currentUser,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      setUser(currentUser);
    }
  }, [currentUser, setUser, isAuthenticated]);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  const handleLogout = () => {
    clearToken();
    // dispatch(logout());
  };

  const handleUpgradeSubscription = async () => {
    try {
      // Implement subscription upgrade logic here
      console.log('Upgrading subscription...');
      // You might want to redirect to a payment page or show a modal
    } catch (error) {
      console.error('Failed to upgrade subscription:', error);
      // You might want to show an error toast here
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-100"> 
    
      <Sidebar collapse = {isSidebarCollapsed}>
        <UserMenu collapse = {isSidebarCollapsed} onLogout={handleLogout} />
      </Sidebar>
      <main  className={`transition-all duration-300 ${
          isSidebarCollapsed ? 'ml-16' : 'ml-64'
        } flex-1 p-8`}>
      <button onClick={toggleSidebar}><AlignJustify /> </button>
        <LanguageSwitcher />
        <Outlet
          context={{
            currentUser,
            handleUpgradeSubscription, 
            isSidebarCollapsed
          }}
        />
      </main>
    </div>
  );
};
// Move routes and router back outside ProtectedLayout
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Auth />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/" element={<ProtectedLayout />}>
        <Route index element={<ProductSearch />} />
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="reverse-search" element={<ReverseSearch />} />
        <Route path="scan-list" element={<ScanList />} />
        <Route path="suppliers" element={<SuppliersList />} />
        <Route path="affiliz-suppliers" element={<AffilizSuppliersPage />} />
        <Route path="product/:id" element={<ProductDetails />} />
        <Route path="profile" element={<ProfileForm />} />
        <Route path="admin/users" element={<UserListPage />} />
        <Route path="saved-asins" element={<SavedAsinsPage />} />
        <Route path="saved-searches" element={<SavedSearchesPage />} />
        <Route path="account" element={<AccountPage />} />
        <Route path="/support" element={<SupportForm />} />
        <Route path="clear-data" element={<ClearDataPage />} /> 
        <Route path="/scanpreview" element={<ScanPreviewPage />} />
      </Route>
    </>
  ),
  {
    future: {
      // @ts-expect-error future flag pas encore dans les types
      v7_startTransition: true,
    },
  }
);

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <UserProvider>
        <RouterProvider router={router} />
        <Toaster position="top-right" />
      </UserProvider>
    </DndProvider>
  );
}

export default App;
