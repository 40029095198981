import React from 'react';
import { Loader, Maximize2 } from 'lucide-react';
import { STATUS_CLASSNAMES } from '../../../types/supplier';
import { TSupplier } from '../../../apiClient/suppliers/suppliers';
import { TProcessingSupplier } from '../../../store/suppliers/suppliers';

interface StatusCellProps {
  value: string;
  data: TSupplier;
  onStatusClick: (data: TSupplier) => void;
  processingData: Record<string, TProcessingSupplier>;
  onProcessDataClick?: (data: TProcessingSupplier) => void;
}

export const StatusCell: React.FC<StatusCellProps> = ({
  value,
  data,
  onStatusClick,
  processingData,
  onProcessDataClick,
}) => {
  const _processingData: TProcessingSupplier = processingData[
    data?._id
  ] as unknown as TProcessingSupplier;
  const status = _processingData?.status || value;
  const isProcessing = status === 'PROCESSING';

  return (
    <>
      <button
        type="button"
        disabled={!_processingData}
        onDoubleClick={() => onStatusClick(data)}
        className={`flex items-center gap-2 rounded-full px-3 py-1 text-sm ${STATUS_CLASSNAMES[status]}`}
      >
        {isProcessing && <Loader className="animate-spin" size={14} />}
        <span>{status}</span>
        {Object.keys(_processingData || {}).length > 0 ? '*' : ''}
        <span className="ml-1 opacity-50 hover:opacity-100">
          {Object.keys(_processingData || {}).length > 0 && (
            <Maximize2
              size={14}
              onClick={() => onProcessDataClick?.(_processingData)}
              className="cursor-pointer"
            />
          )}
        </span>
      </button>
    </>
  );
};
