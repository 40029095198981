import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpCircle, X } from 'lucide-react';
import { formatFilterStateToSearchConditions } from '../../utils/filter';
import { toast } from 'react-hot-toast';

type Tag = string;
interface SearchBarProps {
  onSubmit: (values: Record<string, unknown>) => void;
}
export const SearchBar: React.FC<SearchBarProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [includeTags, setIncludeTags] = useState<Tag[]>([]);
  const [excludeTags, setExcludeTags] = useState<Tag[]>([]);
  const [includeInput, setIncludeInput] = useState('');
  const [excludeInput, setExcludeInput] = useState('');

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, type: 'include' | 'exclude') => {
    if (e.key === 'Enter' && e.currentTarget.value) {
      const newTag = e.currentTarget.value;
      if (type === 'include') {
        if (includeTags.length >= 3) {
          toast.error('Maximum 3 tags allowed');
          return;
        }
        setIncludeTags([...includeTags, newTag]);
        setIncludeInput('');
      } else {
        if (excludeTags.length >= 3) {
          toast.error('Maximum 3 tags allowed');
          return;
        }
        setExcludeTags([...excludeTags, newTag]);
        setExcludeInput('');
      }
    }
  };

  const removeTag = (value: string, type: 'include' | 'exclude') => {
    if (type === 'include') {
      setIncludeTags(includeTags.filter((tag) => tag !== value));
    } else {
      setExcludeTags(excludeTags.filter((tag) => tag !== value));
    }
  };

  const onSubmitSearch = () => {
    // Filter tags into ASIN and EAN lists
    const asinRegex = /^[A-Z0-9]{10}$/;
    const eanRegex = /^\d{13}$/;

    const asinList = includeTags.filter(tag => asinRegex.test(tag));
    const eanList = includeTags.filter(tag => eanRegex.test(tag));
    const excludeAsinList = excludeTags.filter(tag => asinRegex.test(tag));
    const excludeEanList = excludeTags.filter(tag => eanRegex.test(tag));
    const searchConditions = formatFilterStateToSearchConditions({
      asinList,
      eanList,
      excludeList: excludeAsinList,
      eanExcludeList: excludeEanList,
    });
    onSubmit?.(searchConditions);
  };

  return (
    <div className="flex items-start gap-4 p-6">
      <div className="flex-1">
        <div className="relative">
          <label className="mb-2 flex items-center gap-2 text-sm font-medium text-green-600">
            {t('search.include')}
            <HelpCircle size={16} className="text-gray-400" />
          </label>
          <input
            type="text"
            value={includeInput}
            onChange={(e) => setIncludeInput(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, 'include')}
            className="w-full rounded-lg border border-green-300 px-3 py-2 shadow-sm outline-none focus:border-green-500 focus:ring-2 focus:ring-green-200"
            placeholder="Enter ASIN or EAN..."
          />
          <div className="mt-2 flex flex-wrap gap-2">
            {includeTags.map((tag) => (
              <span
                key={tag}
                className="flex items-center gap-1 rounded-full bg-green-50 px-3 py-1 text-sm text-green-700 shadow-sm"
              >
                {tag}
                <button onClick={() => removeTag(tag, 'include')} className="hover:text-green-900">
                  <X size={14} />
                </button>
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="flex-1">
        <div className="relative">
          <label className="mb-2 flex items-center gap-2 text-sm font-medium text-red-600">
            {t('search.exclude')}
            <HelpCircle size={16} className="text-gray-400" />
          </label>
          <input
            type="text"
            value={excludeInput}
            onChange={(e) => setExcludeInput(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, 'exclude')}
            className="w-full rounded-lg border border-red-300 px-3 py-2 shadow-sm outline-none focus:border-red-500 focus:ring-2 focus:ring-red-200"
            placeholder="Enter ASIN or EAN..."
          />
          <div className="mt-2 flex flex-wrap gap-2">
            {excludeTags.map((tag) => (
              <span
                key={tag}
                className="flex items-center gap-1 rounded-full bg-red-50 px-3 py-1 text-sm text-red-700 shadow-sm"
              >
                {tag}
                <button onClick={() => removeTag(tag, 'exclude')} className="hover:text-red-900">
                  <X size={14} />
                </button>
              </span>
            ))}
          </div>
        </div>
      </div>
      <button
        onClick={onSubmitSearch}
        className="h-10 self-end rounded-lg bg-orange-500 px-6 py-2 text-white shadow-sm transition-colors hover:bg-orange-600"
      >
        {t('search.searchButton')}
      </button>
    </div>
  );
};
