import React ,{useState} from 'react';
import { useTranslation } from 'react-i18next';
import { X, RotateCcw } from 'lucide-react';

interface SuppliersFilterProps {
  isOpen: boolean;
  onClose: () => void; 
  onApplyFilters: (filters: { type?: string, country?: string, category?: string }) => void;
}

export const SuppliersFilter: React.FC<SuppliersFilterProps> = ({ isOpen, onClose  ,onApplyFilters}) => {
  const { t } = useTranslation(); 
  const [type, setType] = useState<string | undefined>('');
  const [country, setCountry] = useState<string | undefined>('');
 


  const handleApply = () => {
    onApplyFilters({ type, country });
    onClose();
  };

  return (
    <>
      <div
        className={`fixed inset-0 z-40 bg-black bg-opacity-25 transition-opacity ${isOpen ? 'opacity-100' : 'pointer-events-none opacity-0'}`}
        onClick={onClose}
      />

      <div
        className={`fixed bottom-0 right-0 top-0 z-50 w-96 transform bg-white shadow-xl transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'} `}
      >
        <div className="flex h-full flex-col">
          <div className="flex items-center justify-between border-b border-gray-100 p-6">
            <h2 className="text-xl font-semibold">{t('filters.title')}</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <X size={24} />
            </button>
          </div>

          <div className="flex-1 space-y-8 overflow-y-auto p-6">
            <div>
              <h3 className="mb-4 text-sm font-semibold text-gray-900">
                {t('filters.suppliers.type')}
              </h3>
              <div className="space-y-3">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500"
                    checked={type === 'Site Marchand'}
                    onChange={() => setType(type === 'Site Marchand' ? '' : 'Site Marchand')}
                  />
                  <span>Site marchand</span>
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500" 
                    checked={type === 'Site professionnel'}
                    onChange={() => setType(type === 'Site professionnel' ? '' : 'Site professionnel')}
                  />
                  <span>Site professionnel</span>
                </label>
              </div>
            </div>

            <div>
              <h3 className="mb-4 text-sm font-semibold text-gray-900">Pays</h3>
              <select className="w-full rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm" value={country}
                onChange={(e) => setCountry(e.target.value)}>
                <option value="">{t('filters.suppliers.selectType')}</option>
                <option value="FR">France</option>
                <option value="DE">Allemagne</option>
                <option value="IT">Italie</option>
                <option value="ES">Espagne</option>
                <option value="UK">Royaume-Uni</option>
                <option value="BE">Belgique</option>
                <option value="NL">Pays-Bas</option>
                <option value="RO">Roumanie</option>
                <option value="PL">Pologne</option>
              </select>
            </div>

           
          </div>

          <div className="border-t border-gray-100 bg-gray-50 p-6">
            <div className="flex items-center justify-between gap-4">
              <button className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900">
                <RotateCcw size={16} />
                <span>{t('common.reset')}</span>
              </button>
              <button className="rounded-lg bg-orange-500 px-4 py-2 text-sm text-white hover:bg-orange-600" 
               onClick={handleApply}>
                {t('common.apply')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
