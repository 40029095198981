import React, { useCallback } from 'react';
import { Eye, Download } from 'lucide-react';
import { ICellRendererParams } from 'ag-grid-community';
import { TScan } from '../../../apiClient/scans/scans';
import * as apiClient from '../../../apiClient';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
interface ActionCellProps {
  params: ICellRendererParams<TScan>;
}

export const ActionCell: React.FC<ActionCellProps> = ({ params }) => {
  const navigate = useNavigate();
  const handlePreview = useCallback(async () => {
    navigate('/scanpreview', { state: { scan: params.data, scanId: params.data?._id } });
  }, [navigate, params]);

  const download = async () => {
    console.log(params.data);
    if (params.data?.status === 'completed') {
      try {
        const content = await apiClient.products.post('scan/download', {
          fileId: params.data?._id,
          type: 'processed',
        });
        console.log(content);
        const blob = new Blob([content as unknown as BlobPart], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = `${Date.now()}-exported_data.csv`;
        downloadLink.click();

        window.URL.revokeObjectURL(url);
      } catch (error) {
        toast.error(error as string);
      }
    }
  };
  return (
    <div className="flex items-center gap-2">
      <button className="text-gray-400 hover:text-gray-600" onClick={handlePreview}>
        <Eye size={20} />
      </button>
      <button onClick={download} className="text-gray-400 hover:text-gray-600">
        <Download size={20} />
      </button>
    </div>
  );
};
