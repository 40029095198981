import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import {
  ICellRendererParams,
  ColDef,
  ValueFormatterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { TScan } from '../../apiClient/scans/scans';
import * as Store from '../../store';
import { StatusCell } from './StatusCell/StatusCell';
import { ActionCell } from './ActionCell/ActionCell';
//import { ScanPreviewModal } from './ScanPreviewModal';
import { EmptyState } from './EmptyState';
//import { EXPORT_FIELDS } from './constants';
import { useSocketConnection } from '../../hooks/useSocketConnection';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { toast } from 'react-hot-toast'; 
import { Star } from 'lucide-react'; 
import { ImageTooltip } from '../ProductList/ImageTooltip'

interface ScanTableProps {
  scans: TScan[];
  onFileLoaded: (data: string[][], file?: File) => void;
}

const MarketPlaceFlags: Record<string, string> = {
  ALL: '🌎',
  US: '🇺🇸',
  FR: '🇫🇷',
  ES: '🇪🇸',
  IT: '🇮🇹',
  DE: '🇩🇪',
} as const;
interface PreviewData {
  [key: string]: string | number;
}    

export const SavedAsinsComponent: FC<{ params: ICellRendererParams }> = ({ params }) => {
  const { t } = useTranslation();
  const asins = Store.useAppSelector(Store.userSettings.selectors.selectSavedAsins);
  const isSaved = asins.includes(params.data.products[0].asin);
  const dispatch = Store.useDispatch();
  const onSaveAsin = () => {
    if (isSaved) {
      dispatch(Store.userSettings.unsaveAsin(params.data.products[0].asin));
      toast.success(t('productList.asin.removedFromFavorites'));
    } else {
      dispatch(Store.userSettings.saveAsin(params.data.products[0].asin));
      toast.success(t('productList.asin.addedToFavorites'));
    }
  };
  return (
    <button
      className={`flex items-center justify-center text-${isSaved ? 'yellow' : 'gray'}-400 hover:text-yellow-500`}
      onClick={onSaveAsin}
    >
      <Star size={20} fill={isSaved ? 'currentColor' : 'none'} />
    </button>
  );
}   

// 
export const ImageCell: FC<{ params: ICellRendererParams }> = ({ params }) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [isStockHovered, setIsStockHovered] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="relative inline-block"
    >
      <div className="relative">
        <img
          src={params.data.products[0].amazonImageUrl}
          alt=""
          className="h-16 w-16 rounded object-cover transition-transform duration-300 ease-in-out"
        />
        {params.data.products[0].stock !== undefined && params.data.products[0].stock !== null && (
          <>
            <div
              onMouseEnter={() => setIsStockHovered(true)}
              onMouseLeave={() => setIsStockHovered(false)}
              className={`absolute -right-1 -top-1 h-3 w-3 rounded-full ${
                params.data.products[0].stock ? 'bg-green-500' : 'bg-red-500'
              }`}
            />
            {isStockHovered && (
              <div className="absolute left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 transform whitespace-nowrap rounded bg-gray-900 px-2 py-1 text-xs text-white">
                {params.data.products[0].stock ? t('common.inStock') : t('common.outOfStock')}
              </div>
            )}
          </>
        )}
      </div>
      <ImageTooltip imageUrl={params.data.products[0].amazonImageUrl} isVisible={isHovered} />
    </div>
  );
};


export const ScanTable: React.FC<ScanTableProps> = ({ onFileLoaded, scans }) => {
  const { t } = useTranslation();
  const gridRef = useRef(null);
  const dispatch = Store.useDispatch();
  //const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  //const [previewData, setPreviewData] = useState<PreviewData[]>([]);
  const socketIO = useSocketConnection(); 



  const mapPreviewData = (data: PreviewData[]) => {
    return data.map((item) => ({
      products: [
        {
          asin: item.ASIN || '',
          ean: item.EAN || '',
          amazonPrice: parseFloat(String(item['Prix Amazon'])) || 0,
          amazonUrl: item['Lien Amazon'] || '',
          amazonImageUrl: item['Image Amazon'] || '',
          supplierPrice: parseFloat(String(item['Prix Fournisseur'])) || 0,
        //  supplierPriceHT: parseFloat(String(item['Frais totaux'])) || 0,
          supplierUrl: item['Lien Fournisseur'] || '',
          profit: parseFloat(String(item.Profit)) || 0,
          roi: parseFloat(String(item.ROI)) || 0,
          title: item.Titre || '',
          marketplace: item.Marketplace || '',
          stock: item['Fulfillment Amazon'] === '1', 
          sale_rank : parseFloat(String(item['Meilleur classement'])) || 0,
          
        },
      ],
      count: null,
      lastUpdateDate: new Date().toISOString(), 
    }));
  };

  const sortedScans = useMemo(() => {
    return scans.sort((a, b) => {
      return new Date(b.dateOfCreation).getTime() - new Date(a.dateOfCreation).getTime();
    });
  }, [scans]);

  const handlePreview = useCallback(
    async (scanId: string) => {
      try {
        const data = await dispatch(Store.scans.previewScan({ fileId: scanId })); 
        console.log("data is ==>" , data) 
        const transformedData = mapPreviewData(data.payload as PreviewData[]);
        console.log('Transformed Data is ==>:', transformedData); 
      //  setPreviewData(transformedData); 

        return transformedData
       // setIsPreviewModalOpen(true);
      } catch (error) {
        console.error('Preview error:', error); 
        return null
      }
    },
    [dispatch]
  );

  const columnDefs = useMemo<ColDef<TScan>[]>(
    () => [
      {
        headerName: t('scans.name'),
        field: 'name',
        editable: true,
        valueFormatter: (params: ValueFormatterParams<TScan>) => {
          return params.data?.name || '';
        },
        valueSetter: (params: ValueSetterParams<TScan>) => {
          if (params.newValue !== params.oldValue) {
            dispatch(
              Store.scans.updateScan({ id: params.data._id, data: { name: params.newValue } })
            );
          }
          return false;
        },
      },
      {
        headerName: t('scans.date'),
        field: 'dateOfCreation',
      },
      {
        headerName: t('scans.scannedProducts'),
        field: 'numberOfProducts',
      },
      {
        headerName: t('scans.processedProducts'),
        field: 'numberOfProductsProcessed',
        valueFormatter: (params: ValueFormatterParams<TScan>) => {
          return params.value ? params.value.toString() : '0';
        },
      },
      {
        headerName: t('common.marketplace'),
        field: 'marketPlaceIds',
        cellRenderer: (params: ICellRendererParams<TScan>) => {
          const marketplaceIds = params.value ? params.value : ['ALL'];
          return marketplaceIds.map((marketplaceId: string) => MarketPlaceFlags[marketplaceId]);
        },
      },
      {
        headerName: t('suppliers.status'),
        field: 'status',
        cellRenderer: (params: ICellRendererParams<TScan>) => {
          return socketIO ? <StatusCell params={params} socketIO={socketIO} /> : null;
        },
      },
      {
        headerName: 'Actions',
        width: 100,
        cellRenderer: (params: ICellRendererParams<TScan>) => (
          <ActionCell params={params} onPreview={handlePreview} />
        ),
        sortable: false,
        filter: false,
      },
    ],
    [t, dispatch, socketIO, handlePreview]
  );
//console.log("prview data is ====>" , previewData)  







  return (
    <div className="h-[600px] w-full">
      {scans.length === 0 ? (
        <EmptyState onFileLoaded={onFileLoaded} />
      ) : (
        <>
          <div className="ag-theme-alpine h-full">
            <AgGridReact<TScan>
              ref={gridRef}
              rowData={sortedScans}
              columnDefs={columnDefs}
              defaultColDef={{
                sortable: true,
                resizable: true,
              }}
              pagination={true}
              paginationPageSize={50}
            />
          </div>

         {
          /* 

           <ScanPreviewModal
            isOpen={isPreviewModalOpen}
            onClose={() => setIsPreviewModalOpen(false)}
            data={previewData}
            columnDefs={previewColumnDefs}
          />
          */
         }
        </>
      )}
    </div>
  );
};
