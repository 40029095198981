import React, { useEffect, useState } from 'react';
import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { ScanTable } from './ScanTable';
import { ScanUploadModal } from './ScanUploadModal';
import { PageLayout } from '../common/PageLayout';
import { PageHeader } from '../common/PageHeader';
import { SearchToolbar } from '../common/SearchToolbar';
import { TScan } from '../../apiClient/scans/scans';
import toast from 'react-hot-toast';
import * as Store from '../../store';
import { AmazonSellerForm } from '../Auth/AmazonSellerForm';
export const ScanList: React.FC = () => {

  const { t } = useTranslation();
  const user = Store.useAppSelector(Store.auth.selectUser);
  const dispatch = Store.useDispatch();
  const [isMappingModalOpen, setIsMappingModalOpen] = useState(false);
  const [isInputUploadModalOpen, setIsInputUploadModalOpen] = useState(false);
  const [isDataUploadModalOpen, setIsDataUploadModalOpen] = useState(false);
  const selectedMarketplaces = Store.useAppSelector(
    Store.userSettings.selectors.selectSelectedMarketplaces
  );
  const [searchValue, setSearchValue] = useState('');
  const [title, setTitle] = useState('scans');
  const [mapping, setMapping] = useState<{ [key: string]: string }>({});
  const [data, setData] = useState<string[][]>([]);
  const [file, setFile] = useState<File | null>(null);
  const scans: TScan[] = Store.useAppSelector(Store.scans.selectors.selectScanCollection);

  useEffect(() => {
    dispatch(Store.scans.fetchScans());
  }, [dispatch]);

  const onInputUploadValidateOrClosed = (data?: string[][], file?: File) => {
    // only allow 2500 rows
    if (data && data.length > 2505) {
      toast.error(t("scans.error.maxRows"));
      return;
    }
    if (data) {
      setData(data);
      setIsMappingModalOpen(true);
    }
    if (file) {
      setFile(file);
    }
    setIsInputUploadModalOpen(false);
    setIsDataUploadModalOpen(false);
  };

  const onDataUploadValidateOrClosed = async (validated: boolean) => {
    if (validated) {
      if (selectedMarketplaces.length === 0) {
        // TODO: Add error notification
        toast.error(t('scans.error.noMarketplaceSelected'));
        return;
      }
      if (!title) {
        toast.error(t('scans.error.noTitle'));
        return;
      }
      if (!file) {
        toast.error(t('scans.error.noFile'));
        return;
      }

      try {
        const formData = new FormData();
        if (!file) {
          throw new Error('No file');
        }
        formData.append('file', file);
        formData.append('name', title);
        formData.append('marketplaceIds', selectedMarketplaces.join(','));
        formData.append('mapping', JSON.stringify(mapping));
        formData.append('numberOfProducts', (data.length - 1).toString());
        formData.append('vatRate', '0');

        const _data = await dispatch(
          Store.scans.createScan({
            data: formData,
            options: {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          })
        );
        console.log('data', _data);
        if (_data.type === 'scans/createScan/fulfilled') {
          toast.success(t('scans.success.created'));
          setIsDataUploadModalOpen(false);
          setMapping({});
          setData([]);
          setFile(null);
          setTitle('');
        } else {
          throw new Error((_data as unknown as { error: { message: string } })?.error?.message);
        }
      } catch (error) {
        console.error(error);
        toast.error(t('scans.error.creation') + ': ' + (error as Error)?.message);
      }
    } else {
      setIsDataUploadModalOpen(false);
      setMapping({});
      setData([]);
      setFile(null);
      setTitle('');
    }
  };

  const onMappinValidateOrClosed = ({
    title,
    price,
    id,
  }: {
    title?: string;
    price?: string;
    id?: string;
  }) => {
    if (title && price && id) {
      setIsMappingModalOpen(false);
      setIsDataUploadModalOpen(false);
      setIsDataUploadModalOpen(true);
      setMapping({
        title,
        price,
        id,
      });
    } else if (!price && !id && !title) {
      setIsMappingModalOpen(false);
      setIsInputUploadModalOpen(true);
    } else {
      setIsMappingModalOpen(false);
      setIsInputUploadModalOpen(false);
    }
  };
  

  return (
   
       <PageLayout
      header={
        <PageHeader
          title={t('scans.title')}
          description={t('scans.description')}
          actions={
            <button
              disabled={user?.isSellerApiConnected === false}
              onClick={() => {
                setIsMappingModalOpen(false);
                setIsInputUploadModalOpen(true);
                setIsDataUploadModalOpen(false);
              }}
              className="flex items-center gap-2 rounded-lg bg-orange-500 px-4 py-2 text-white transition-colors hover:bg-orange-600"
            >
              <Plus size={20} />
              <span>{t('scans.addScan')}</span>
            </button>
          }
        >
          {!user?.isSellerApiConnected && (
            <div className="flex items-center gap-2 justify-center">
              <AmazonSellerForm />
            </div>
          )}
          <SearchToolbar
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            onFilterClick={() => {}}
            onSearch={() => {}}
            placeholder={t('scans.searchPlaceholder')}
          />
        </PageHeader>
      }
    >
      <ScanTable
        scans={scans}
        onFileLoaded={(data, file) => onInputUploadValidateOrClosed(data, file)}
      />

      <ScanUploadModal
        onClose={() => {
          setIsMappingModalOpen(false);
          setIsInputUploadModalOpen(false);
          setIsDataUploadModalOpen(false);
        }}
        isMappingModalOpen={isMappingModalOpen}
        isInputUploadModalOpen={isInputUploadModalOpen}
        isDataUploadModalOpen={isDataUploadModalOpen}
        data={data}
        mapping={mapping}
        onTitleChange={setTitle}
        title={title}
        onMappingValidateOrClosed={onMappinValidateOrClosed}
        onInputUploadValidateOrClosed={onInputUploadValidateOrClosed}
        onDataUploadValidateOrClosed={onDataUploadValidateOrClosed}
      />
    </PageLayout>

  );
};
