import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ProductList, TGridData } from '../ProductList/ProductList';
import { ProductCategories } from '../Search/ProductCategories';

import { SearchHeader } from '../Search/SearchHeader';
import { useScanFilter } from '../../hooks/useProductSearch';
import { useProductGridConfig } from '../Search/ProductGridConfig';
import { useLocation } from 'react-router-dom';

export const ScanPreviewPage: React.FC = () => {
  const { state } = useLocation();
  const { scan, scanId } = state;
  const [resultData, setResultData] = useState<TGridData[] | null>(null);
  const gridRef = useRef<AgGridReact<TGridData>>(null);
  const didFirstSearch = useRef(false);

  const { data, isLoading, conditions, fetchScanData, scanResultData } = useScanFilter(gridRef);
  const resultRef = useRef<TGridData[]>(scanResultData.docs);

  const { gridOptions } = useProductGridConfig();

  const handleSubmit = (values: Record<string, unknown>) => {
    delete values.supplier;
    console.log({ values });
    const newScanResultData = {
      ...scanResultData,
      docs: scanResultData.docs.map((doc) => ({
        ...doc,
        products: doc.products.filter((product) => {
          const res = (Object.keys(values) as Array<keyof typeof values>).every((key) => {
            if (!(key in product)) return true;
            const value = values[key];
            const productKey = key as keyof typeof product;
            const productValue = product[productKey];

            if (typeof value === 'object' && value !== null && 'min' in value && 'max' in value) {
              const range = value as { min: number; max: number };
              return (
                typeof productValue === 'number' &&
                productValue >= range.min &&
                productValue <= range.max
              );
            }
            if (typeof productValue === 'string' && typeof value === 'string') {
              return productValue.toLowerCase().includes(value.toLowerCase());
            }
            if (typeof productValue === 'boolean' && typeof value === 'boolean') {
              return productValue !== value;
            }
            if (typeof productValue === 'number' && typeof value === 'number') {
              return productValue === value;
            }
            // aray
            if (Array.isArray(value)) {
              return value.includes(productValue);
            }
            return productValue === value;
          });
          return res;
        }),
      })),
    };
    resultRef.current = newScanResultData.docs;
    setResultData(newScanResultData.docs.filter((doc) => doc.products.length > 0));
  };

  useEffect(() => {}, []);
  useEffect(() => {
    console.log(scanId);
    if (data.docs.length === 0 && !didFirstSearch.current) {
      console.log('fetching data');
      fetchScanData(scanId);
      didFirstSearch.current = true;
    }
  }, [scanId, fetchScanData, data.docs]);

  return (
    <div className="space-y-6">
      <SearchHeader
        onSubmit={handleSubmit}
        conditions={JSON.stringify(conditions)}
        isScanPreview={true}
        scan={scan}
      />

      <div className="rounded-lg bg-white p-6 shadow-sm">
        <ProductCategories />
      </div>

      <ProductList
        isScanPreview={true}
        docs={resultData || scanResultData.docs}
        isLoading={isLoading}
        meta={scanResultData.meta}
        gridRef={gridRef}
        gridOptions={gridOptions}
        conditions={conditions}
      />
    </div>
  );
};
