import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchFilters } from './SearchFilters';
import { TScan } from '../../apiClient/scans/scans';

interface SearchHeaderProps {
  onSubmit: (values: Record<string, unknown>) => void;
  isScanPreview?: boolean;
  conditions: string;
  scan?: TScan;
}

export const SearchHeader: React.FC<SearchHeaderProps> = ({
  onSubmit,
  conditions,
  isScanPreview,
  scan,
}) => {
  const { t } = useTranslation();

  return (
    <div className="rounded-lg bg-white shadow-sm">
      <div className="p-6">
        <div className="flex flex-col items-start justify-between gap-4 md:flex-row md:items-center">
          <div>
            <h1 className="mb-2 text-2xl font-semibold">
              {t(isScanPreview ? 'scans.scanPreview' : 'search.title') +
                ' ' +
                (isScanPreview ? ': ' + scan?.name : '')}
            </h1>
            {isScanPreview ? (
              <p className="text-gray-600">{t('scans.scanPreviewDescription')}</p>
            ) : (
              <p className="text-gray-600">{t('search.description')}</p>
            )}
          </div>
          <SearchFilters
            onSubmit={onSubmit}
            conditions={conditions}
            isScanPreview={isScanPreview}
          />
        </div>
      </div>
    </div>
  );
};
