import React, { useCallback, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Upload } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import toast from 'react-hot-toast';

interface FileDropzoneProps {
  onFileLoaded: (data: string[][], file?: File) => void;
  className?: string;
}

export const FileDropzone: React.FC<FileDropzoneProps> = ({ onFileLoaded, className }) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const parseFile = useCallback(
    (file: File) => {
      console.log('Parsing file:', file.name, file.type);

      if (!file) {
        console.error('No file provided');
        return;
      }

      const _myFile = file;

      const fileExt = file.name.split('.').pop()?.toLowerCase();
      console.log('File extension:', fileExt);

      if (fileExt === 'csv') {
        Papa.parse(file, {
          complete: (results) => {
            onFileLoaded(results.data as string[][], _myFile);
            results.errors.forEach((error) => {
              console.error('CSV parsing error:', error);
              toast.error(
                t(`dropzone.${error.code}`) + ' ' + t(`dropzone.atIndex`) + ' ' + error.index
              );
            });
            toast.success(t('dropzone.fileLoaded'));
          },
          error: (error) => {
            console.error('CSV parsing error:', error);
            toast.error(t('dropzone.error'));
          },
          delimiter: ',',
          header: false,
          skipEmptyLines: true,
          encoding: 'UTF-8',
        });
      } else if (['xlsx', 'xls'].includes(fileExt || '')) {
        const reader = new FileReader();

        reader.onload = (e) => {
          try {
            const data = e.target?.result;
            if (!data) {
              throw new Error('No data read from file');
            }

            const workbook = XLSX.read(data, {
              type: 'binary',
              cellDates: true,
              cellNF: false,
              cellText: false,
            });

            if (workbook.SheetNames.length === 0) {
              throw new Error('No sheets found in workbook');
            }

            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
              raw: false,
              dateNF: 'yyyy-mm-dd',
            }) as string[][];

            if (parsedData.length === 0) {
              throw new Error('No data found in sheet');
            }

            onFileLoaded(parsedData, _myFile);
            toast.success(t('dropzone.fileLoaded'));
          } catch (error) {
            console.error('Excel parsing error:', error);
            toast.error(t('dropzone.error'));
          }
        };

        reader.onerror = (error) => {
          console.error('FileReader error:', error);
          toast.error(t('dropzone.error'));
        };

        reader.readAsBinaryString(file);
      } else {
        console.error('Unsupported file type:', fileExt);
        toast.error(t('dropzone.unsupportedType'));
      }
    },
    [onFileLoaded, t]
  );

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      console.log('File change event:', e);
      const file = e.target.files?.[0];
      if (file) {
        parseFile(file);
      }
    },
    [parseFile]
  );

  const handleButtonClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    console.log('Button clicked');
    e.preventDefault();
    e.stopPropagation();
    fileInputRef.current?.click();
  }, []);

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: File[] }) {
        console.log('Drop event:', item);
        const files = item.files;
        if (files?.length > 0) {
          parseFile(files[0]);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [parseFile]
  );

  return (
    <div
      ref={drop}
      className={`flex flex-col items-center justify-center rounded-lg border-2 border-dashed p-8 transition-colors ${isOver ? 'border-blue-500 bg-blue-50' : 'border-gray-300'} ${className} `}
    >
      <input
        ref={fileInputRef}
        type="file"
        onChange={handleFileChange}
        accept=".csv,.xlsx,.xls"
        className="hidden"
      />
      <button
        type="button"
        onClick={handleButtonClick}
        className="flex w-full cursor-pointer flex-col items-center rounded-lg p-4 hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <Upload className="mb-4 h-12 w-12 text-gray-400" />
        <p className="text-center text-sm text-gray-600">
          {isOver ? (
            t('dropzone.dragActive')
          ) : (
            <>
              {t('dropzone.dragInactive')}
              <br />
              <span className="text-blue-500">{t('dropzone.browse')}</span>
            </>
          )}
        </p>
        <p className="mt-2 text-xs text-gray-500">{t('dropzone.supportedFormats')}</p>
      </button>
    </div>
  );
};
