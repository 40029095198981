import { useCallback, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import * as apiClient from '../apiClient';
import { toast } from 'react-hot-toast';
import { TGridData } from '../components/ProductList/ProductList';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Product } from '../apiClient/products/products';

type TScanExportedData = {
  ASIN: string;
  EAN: string;
  UPC: string;
  Titre: string;
  'Prix Amazon': number;
  Marketplace: string;
  'Prix Fournisseur': number;
  Profit: number;
  ROI: number;
  'Meilleur classement': number;
  Categorie: string;
  Categories: string;
  'Type de vendeur': string;
  'Lien Amazon': string;
  'Lien Fournisseur': string;
  'Image Amazon': string;
  'Vendu en lot': boolean;
  'Fulfillment Amazon': boolean;
  'Frais totaux': number;
  'Devise des frais totaux': string;
};

type TResultData = {
  docs: TGridData[];
  meta: {
    hasNextPage: boolean;
    hasPrevPage: boolean;
    limit: number;
    nextPage: number;
    page: number;
    pagingCounter: number;
    prevPage: number;
    totalDocs: number;
    totalPages: number;
  };
};
export const useProductSearch = (gridRef?: React.RefObject<AgGridReact>) => {
  const navigate = useNavigate();
  const [conditions, setConditions] = useState<Record<string, unknown>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TResultData>({
    docs: [],
    meta: {
      hasNextPage: false,
      hasPrevPage: false,
      limit: 0,
      nextPage: 0,
      page: 0,
      pagingCounter: 0,
      prevPage: 0,
      totalDocs: 0,
      totalPages: 0,
    },
  });

  const paginateSearch = async (page: number, poConditions?: Record<string, unknown>) => {
    if (!conditions && !poConditions) return;
    navigate('', { state: { searchQuery: poConditions || conditions }, replace: true });
    const searchConditions = poConditions || conditions;
    if (Object.keys(searchConditions || {}).length === 0) return;
    setIsLoading(true);
    gridRef?.current?.api.showLoadingOverlay();

    try {
      const res = await apiClient.products.findByConditions('search', {
        conditions: poConditions || conditions,
        paginationOption: { page },
        group: {
          _id: '$asin',
          count: { $first: '$count' }, // Assuming you want to keep some count
          profit: { $first: '$profit' }, // Preserve the first profit value
          lastUpdateDate: { $first: '$lastUpdateDate' }, // Preserve the first last update date
        },
        sort: {
          accuracy: -1,
          brandFound: -1,
          lastUpdateDate: -1,
          profit: -1,
        },
      });
      setData(res.data);
    } catch (err: unknown) {
      console.error(err);
      toast.error(err as string);
    } finally {
      setIsLoading(false);
      gridRef?.current?.api.hideOverlay();
    }
  };

  const handleSubmit = (values: Record<string, unknown>) => {
    setConditions(values);
    paginateSearch(1, values);
  };

  return {
    data,
    handleSubmit,
    paginateSearch,
    isLoading,

    conditions,
  };
};

export const useScanFilter = (gridRef?: React.RefObject<AgGridReact>) => {
  const limit = 10;
  const [scanResultData, setScanResultData] = useState<TResultData>({
    docs: [],
    meta: {
      hasNextPage: false,
      hasPrevPage: false,
      limit: 0,
      nextPage: 0,
      page: 0,
      pagingCounter: 0,
      prevPage: 0,
      totalDocs: 0,
      totalPages: 0,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [conditions, setConditions] = useState<Record<string, unknown>>({});
  const [data, setData] = useState<TResultData>({
    docs: [],
    meta: {
      hasNextPage: false,
      hasPrevPage: false,
      limit: 0,
      nextPage: 0,
      page: 0,
      pagingCounter: 0,
      prevPage: 0,
      totalDocs: 0,
      totalPages: 0,
    },
  });

  const formatData = useCallback((data: TScanExportedData[]) => {
    return data.map((item) => {
      const supplierPrice = parseFloat(item['Prix Fournisseur'] as unknown as string);
      const supplierPriceTTC = supplierPrice + 0.2 * supplierPrice;
      return {
        _id: item['ASIN'],
        asin: item['ASIN'],
        ean: item['EAN'],
        upc: item['UPC'],
        title: item['Titre'],
        amazonPrice: parseFloat(item['Prix Amazon'] as unknown as string),
        amazonCountry: item['Marketplace'],
        supplierPrice,
        supplierPriceHT: supplierPrice,
        supplierPriceTTC,
        profit: parseFloat(item['Profit'] as unknown as string),
        roi: parseFloat(item['ROI'] as unknown as string),
        highestRank: parseFloat(item['Meilleur classement'] as unknown as string),
        category: item['Categorie'],
        categories: item['Categories'],
        sellerType: item['Type de vendeur'],
        amazonUrl: item['Lien Amazon'],
        supplierUrl: item['Lien Fournisseur'],
        amazonImageUrl: item['Image Amazon'],
        isGrouped: Boolean(item['Vendu en lot']) || false,
        IsAmazonFulfilled: Boolean(item['Fulfillment Amazon']) || false,
        totalFeesEstimate: parseFloat(item['Frais totaux'] as unknown as string),
        totalFeesEstimateCurrency: parseFloat(item['Devise des frais totaux'] as unknown as string),
        lastUpdateDate: new Date(),
        estimatedSales: 0,
        profitPerMonth: 0,
        supplier: '',
        supplierCountry: '',
        estimatedProfit: 0,
        estimatedProfitPerMonth: 0,
        supplierImgUrl: '',
        name: item['Titre'],
        stock: 0,
        supplierProductName: '',
      } as unknown as Product;
    });
  }, []);

  const fetchScanData = useCallback(
    async (scanId: string) => {
      console.log('===============> fetching data');
      gridRef?.current?.api?.showLoadingOverlay();
      setIsLoading(true);
      const response = await apiClient.base.get('products/scan/preview/?fileId=' + scanId);
      const formattedData = formatData(response.data as TScanExportedData[]);
      console.log('====================================');
      console.log(formattedData);
      console.log('====================================');
      const groupByAsin = _.groupBy(formattedData, 'asin');
      const totalDocs = Object.keys(groupByAsin).length;
      const totalPages = Math.ceil(totalDocs / limit);
      const page = 1;
      const nextPage = page + 1;
      const prevPage = page - 1;
      const hasNextPage = nextPage <= totalPages;
      const hasPrevPage = prevPage >= 1;
      const docs = Object.values(groupByAsin).map((item) => ({
        _id: item[0].asin || '',
        asin: item[0].asin || '',
        products: item,
        count: item.length,
        lastUpdateDate: item[0].lastUpdateDate.toISOString(),
        profit: item[0].profit || 0,
      })) as unknown as TGridData[];
      setScanResultData({
        docs,
        meta: {
          totalDocs,
          totalPages,
          hasNextPage,
          hasPrevPage,
          page,
          pagingCounter: page,
          prevPage,
          nextPage,
          limit,
        },
      });

      setIsLoading(false);
      gridRef?.current?.api?.hideOverlay();
    },
    [formatData, gridRef]
  );

  const paginateSearch = async (page: number, _scanResultData?: TResultData) => {
    console.log('====================================');
    console.log({ scanResultData });
    console.log('====================================');
    const _resultData = _scanResultData || scanResultData;
    console.log('====================================');
    console.log({ _resultData: _resultData.docs.length });
    console.log('====================================');
    const _data = {
      ..._resultData,
      docs: _resultData.docs.slice((page - 1) * limit, page * limit),
    };
    console.log('====================================');
    console.log({ _data });
    console.log('====================================');
    setData(_data);
  };

  const handleSubmit = (values: Record<string, unknown>) => {
    const newScanResultData = {
      ...scanResultData,
      docs: scanResultData.docs.map((doc) => ({
        ...doc,
        products: doc.products.filter((product) => {
          return (Object.keys(values) as Array<keyof typeof values>).every((key) => {
            if (!(key in product)) return true;
            const value = values[key];
            const productKey = key as keyof typeof product;
            const productValue = product[productKey];

            if (typeof value === 'object' && value !== null && 'min' in value && 'max' in value) {
              const range = value as { min: number; max: number };
              return (
                typeof productValue === 'number' &&
                productValue >= range.min &&
                productValue <= range.max
              );
            }
            if (typeof productValue === 'string' && typeof value === 'string') {
              return productValue.toLowerCase().includes(value.toLowerCase());
            }
            if (typeof productValue === 'boolean' && typeof value === 'boolean') {
              return productValue === value;
            }
            if (typeof productValue === 'number' && typeof value === 'number') {
              return productValue === value;
            }
            // aray
            if (Array.isArray(value)) {
              return value.includes(productValue);
            }
            return productValue === value;
          });
        }),
      })),
    };
    setConditions(values);
    paginateSearch(1, newScanResultData);
  };

  return {
    fetchScanData,
    data,
    scanResultData,
    handleSubmit,
    paginateSearch,
    isLoading,
    conditions,
  };
};
