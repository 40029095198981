import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Socket } from 'socket.io-client';
import { ICellRendererParams } from 'ag-grid-community';
import { TScan } from '../../../apiClient/scans/scans';
import * as Store from '../../../store';

interface StatusCellProps {
  params: ICellRendererParams<TScan>;
  socketIO: Socket;
}

const StatusClasses = {
  processing: 'bg-yellow-50 text-yellow-600',
  completed: 'bg-green-50 text-green-600',
  failed: 'bg-red-50 text-red-600',
  pending: 'bg-blue-50 text-blue-600',
};

export const StatusCell: React.FC<StatusCellProps> = ({ params, socketIO }) => {
  const { t } = useTranslation();
  const scanIds = Store.useAppSelector(Store.scans.selectors.selectIds);
  const dispatch = Store.useDispatch();
  const [scanProgressData, setScanProgressData] = useState(0);

  useEffect(() => {
    socketIO.on('SCAN_START', (data) => {
      if (data?.id === params?.data?._id?.toString()) {
        dispatch(Store.scans.fetchScanById(data.id));
      }
    });

    socketIO.on('SCAN_PROGRESS', (data) => {
      if (data.id === params.data?._id?.toString()) {
        setScanProgressData(data.progressPercentage);
      }
    });

    socketIO.on('SCAN_END', (data) => {
      if (data.id === params.data?._id?.toString()) {
        dispatch(Store.scans.fetchScanById(data.id));
      }
    });
  }, [dispatch, params.data?._id, scanIds, socketIO]);

  return (
    <span
      className={`rounded-full px-2 py-1 text-xs ${StatusClasses[params?.value as keyof typeof StatusClasses]} `}
    >
      {t(`scans.status.${params?.value}`)}
      {scanProgressData > 0 && (
        <span className="text-xs text-gray-500">({scanProgressData.toFixed(2)}%)</span>
      )}
    </span>
  );
};
